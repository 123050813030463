import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { Box, Input, Paper } from '@mui/material'
import { useState } from 'react'
import { markDownText } from './markDownText'

export const MarkDown = () => {
    const [text, setText] =useState(markDownText);

console.log('тут')
    return (
        <Box sx={{marginTop: 2}}>
        <h1>Пример синтаксиса markdown</h1>
        <Box sx={{
            gridTemplateColumns: {sm: 'auto', md: '1fr 1fr'},
            display: 'grid',
            gap: '2rem',
            gridGap: '2rem',
        }}>
            <Paper variant="outlined" sx={{padding: '.5rem'}}>
                <Input 
                    multiline
                    autoFocus={true}
                    fullWidth
                    margin="dense"
                    value={text}
                    onChange={event => setText(event.target.value)}
                    size="small" 
                />              
            </Paper>
            <Paper variant="outlined" className="item-text" sx={{gridColumnStart: 'unset'}}>
                <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    children={text}
                />
            </Paper>
        </Box>
        </Box>
    )
} 
